function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return "Android";
    } else {
        return 'iOS';
    }
}

const player = new Plyr('#player', {
    controls: ['play-large', 'play', 'progress', 'fullscreen']
    }
);

function addLink(href, blank) {
  const a = document.querySelector('[data-button]');

  a.href = href;

  if (blank) {
    a.target = '_blank';
  }

}

const setProperContent = () => {
  const mobileSystem = getMobileOperatingSystem();

    if (mobileSystem === "iOS") {
      addLink('https://ar.friendsbench.bricks.plus/ar/AR-Friends-Names-Plane.reality#allowsContentScaling=0', false);

      player.source = {
        type: 'video',
        title: 'ios instructions',
          sources: [
              {
                  src: 'https://ar.friendsbench.bricks.plus/videos/ios.mp4',
                  type: 'video/mp4',
              } 
          ],
      };
    } else {
      addLink('https://lego-friends-ar.bricks.plus/', true);

      player.source = {
        type: 'video',
        title: 'android instructions',
        sources: [
            {
                src: 'https://ar.friendsbench.bricks.plus/videos/android.mp4',
                type: 'video/mp4',
            } 
        ],
    };
    }
}

setProperContent();

// function detectDevice() {
//     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
//         // true for mobile device
//         return 'mobile';
//     } else {
//         // false for not mobile device
//         return 'desktop';
//     }
// }

document.querySelector('[data-button]').addEventListener('click', () => {
    sendAnalytics('arButtonClick');
});

player.on('play', () => {
    sendAnalytics('videoPlayButtonClick');
});
